import Navbar from "../../components/navbar/Navbar";
import './toDo.css';
import { Icon } from '@iconify/react'
import { FC, useEffect, useState } from "react";
import firebase from "../../firebase";

const ToDo = () => {

    const [tickets, setTickets] = useState([""]);
    const [newticket, setnewTicket] = useState("");
    // const [ticketnum, setTicketnum] = useState<number>(0);

    // useEffect(() => {
    //     var Ticketcount: number = parseInt(localStorage.getItem('ticketnum') || '0');
    //     var ticketlist: Array<string> = [];

    //     setTicketnum(Ticketcount);
    //     for (let i = 0; i < Ticketcount; i++) {
    //         ticketlist[i] = localStorage.getItem("ticket" + i) || "Error:001";
    //     }
    //     setTickets(ticketlist);
    // }, [ticketnum]);

    // function addticket(): void {
    //     var Ticketcount = ticketnum;
    //     if (!tickets.includes(newticket) && newticket) {
    //         setTicketnum(Ticketcount + 1);
    //         localStorage.setItem("ticketnum", (Ticketcount + 1).toString());
    //         setTickets([...tickets, newticket]);
    //         localStorage.setItem("ticket" + Ticketcount, newticket);
    //         setnewTicket('');
    //     } else {
    //         newticket ? alert("Can't be two of the same task!") : alert("task can't be null");
    //     }
    // }

    // function removeticket(ticket: string): void {
    //     var Ticketcount: number = ticketnum;
    //     var ticketlist: Array<string> = tickets.filter(choosen => choosen !== ticket);
    //     for (let i = 0; i < Ticketcount - 1; i++) {
    //         localStorage.setItem("ticket" + i, ticketlist[i]);
    //     }
    //     localStorage.removeItem("ticket" + (Ticketcount - 1));
    //     localStorage.setItem("ticketnum", (Ticketcount - 1).toString());
    //     setTicketnum(Ticketcount - 1)
    // }


    const [user, setuser] = useState("")

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            setuser(user?.email ? user.email : "");
            console.log(user?.email);
        })
    }, [])

    const ref = firebase.firestore().collection('toDoList');

    useEffect(() => {
        const doc = ref.doc(user || 'dummy');
        doc.get().then(exist => exist.exists).then(doexist => {
            var ticketlist = [""];
            if(doexist && user.includes('@')){
                ticketlist=doc.get().ticketlist;
            }else{
                doc.set({ticketlist:[]});
            }
        });
    }, [user])



    useEffect(() => {
        const snapshot = ref.doc(user || 'dummy').onSnapshot(docSnapshot => {
            console.log(docSnapshot.data()?.ticketlist);
            setTickets(docSnapshot.data()?.ticketlist);
        })
        return () => { snapshot(); }
    }, [user])

    function removeticket(ticket) {
        ref.doc(user).set({ ticketlist: [...tickets.filter(tick => tick != ticket)] });
    };
    function addticket() {
        ref.doc(user).set({ ticketlist: [...tickets, newticket] });
        setnewTicket("");
    };

    return (
        (user != "" && tickets) ?
            <div className="App">
                <Navbar />
                <main className='todocontent'>
                    <h1>ToDo list</h1>
                    {/* i need a theme chooser! */}
                    <>
                        {tickets.map((ticket) => {
                            return <div key={ticket} className="ticket">
                                <p className="task">{ticket}</p>
                                <Icon onClick={() => removeticket(ticket)} className="icon" icon="ic:baseline-done-outline" />
                            </div>
                        })}
                        <div className="ticket">
                            <input className="textbox" onChange={e => setnewTicket(e.target.value)} value={newticket}>
                            </input>
                            <Icon onClick={() => addticket()} className="icon" icon="ant-design:plus-circle-outlined" />
                        </div>
                    </>
                </main>
            </div> :
            <div className="App">
                <Navbar />
                <main className='content'>
                    <h1>ToDo list</h1>
                    <p>Please log in!</p>
                </main>
            </div>
    );
}

export default ToDo;