import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; 
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDUFw_hY94Y0-wZ_tlL04Onj9PAkE6qeUk",
  authDomain: "portfolio-c03e9.firebaseapp.com",
  projectId: "portfolio-c03e9",
  storageBucket: "portfolio-c03e9.appspot.com",
  messagingSenderId: "1089734277461",
  appId: "1:1089734277461:web:eaa4254dd2d153bb6597dc",
  measurementId: "G-FW2G63EP39"
};

const app = firebase.initializeApp(firebaseConfig);

export default firebase;