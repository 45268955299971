    //create and shuffle deck
    export function createandshuffle() {
        var deck = [];
        for (let index = 0; index < 10; index++) {
            deck.push('red')
        }
        for (let index = 0; index < 10; index++) {
            deck.push('green')
        }
        for (let index = 0; index < 10; index++) {
            deck.push('purple')
        }
        for (let index = 0; index < 10; index++) {
            deck.push('one')
        }
        for (let index = 0; index < 10; index++) {
            deck.push('two')
        }
        for (let index = 0; index < 10; index++) {
            deck.push('tree')
        }
        deck = deck.sort(() => (Math.random() > .5) ? 1 : -1);
        var hand = [deck[0], deck[1], deck[2], deck[3], deck[4], deck[5], deck[6]]
        deck.shift();
        deck.shift();
        deck.shift();
        deck.shift();
        deck.shift();
        deck.shift();
        deck.shift();
        return [deck, hand];
    };

    //pick funvtion based on source and destination 
    export function functionpicker(source,destination){
        
    }