import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';

interface enemi{
    alive:Boolean;
    positionx:number;
    positiony:number;
    frame:number;
    immune:boolean
}

const Enemy = (prop:enemi) => {

    function tobe(ornot:Boolean){
        return ornot ? "inherit" : "none";
    }

    return (
        <Icon className="icon enemy" icon={(prop.frame==0) ? "mdi:space-invaders" : "bxs:invader"} style={{top:`${prop.positiony}%`,left:`${prop.positionx}%`,display:tobe(prop.alive),color:(prop.immune ? 'red' : 'aliceblue')}}></Icon>
    )
}

export default Enemy