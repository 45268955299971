import Navbar from "../../components/navbar/Navbar";
import '../../App.css';
import './invaders.css';
import { Icon } from '@iconify/react'
import { FC, useCallback, useEffect, useState } from "react";
import './functions';
import { usePlayermanager, useEnemiesmanager, useBulletmanager, useInputmanager, useTouchmanager, useOrientation } from "./functions";
import Enemy from "./Enemy";
import Bullet from "./Bullet";
import { useNavigate } from "react-router-dom";
import firebase from "../../firebase";

const Invaders: FC = () => {

  const player = usePlayermanager();
  const { enemies, setenemies, setdif } = useEnemiesmanager(28);
  const { bullets, setBull, setPos } = useBulletmanager();
  const navigate = useNavigate();
  const landscape = useOrientation();
  const [score, setscore] = useState(0);

  window.screen.orientation.onchange = function (e) { window.location.reload(); };

  const ref = firebase.firestore().collection("invaders_hightscore");

  const [user, setuser] = useState("")

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setuser(user?.email ? user.email : "");
      console.log(user?.email);
    })
  }, [])

  useEffect(() => {
    setPos(player);
  }, [player])

  useEffect(() => {
    setdif(Math.floor(score / 1000));
  }, [score])

  const [gameover, setgameover] = useState(false);

  useEffect(() => {
    if (landscape && gameover) {
      if (user != "") {
        const cityRef = ref.doc(user);
        const doc = cityRef.get().then((user)=>{
          if(score>(user?.data()?.score || 0)){
            cityRef.set({score:score})
            console.log("score set:")
          }
        });
      }
      navigate("/invaders/gameover")
    }
  }, [gameover, landscape,user])


  useEffect(() => {
    var enemiarray = enemies;
    var bulletarray = bullets;
    var collision = false;
    if (enemiarray.some(nmi => nmi.positiony > 80)) { setgameover(true); }
    bulletarray.map((bullet, bulindex) => {
      if (enemiarray.filter(nmi => Math.abs(nmi.positiony - bullet.positiony) < 5).some(nmi => Math.abs(nmi.positionx - bullet.positionx))) {
        enemiarray.filter(nmi => Math.abs(nmi.positiony - bullet.positiony) < 5).filter(nmi => Math.abs(nmi.positionx - bullet.positionx) < 5).forEach(nmi => {
          if (!nmi.immune) {
            nmi.alive = false;
          }
          bulletarray[bulindex].alive = false;
          setscore(score => score + 10);
          collision = true;
        })
      }
      if (collision) {
        collision = false;
        setenemies(enemiarray.filter(nmi => nmi.alive));
        setBull(bulletarray.filter(blt => blt.alive));
      }
    });
  }, [bullets, enemies])


  return (
    landscape ? (<div className="App">
      <Navbar />
      <main className='content'>
        <div className="space">
          <p className="score">Score:{score}</p>
          <Icon className="icon player" icon="ri:space-ship-line" style={{ left: `${player}%` }}></Icon>
          {enemies.map((enemi) => {
            return <Enemy alive={enemi.alive} key={enemi.key} positionx={enemi.positionx} positiony={enemi.positiony} frame={enemi.frame} immune={enemi.immune} />
          })}
          {bullets.map((currentValue) => {
            return <Bullet alive={currentValue.alive} key={currentValue.key} positionx={currentValue.positionx} positiony={currentValue.positiony} />
          })}
        </div>
      </main>
    </div>) : (<div className="App">
      <Navbar />
      <main className='content'>
        <div className="space">
          <p>Change to landscape mode :)</p>
        </div>
      </main>
    </div>)
  );
}

export default Invaders;