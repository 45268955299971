import React, { useEffect, useState } from 'react';
import './ocfunctions';
import './occult.css';
import one from './1.png';
import two from './2.png';
import tree from './3.png';
import red from './red.png';
import green from './green.png';
import purple from './lilac.png';
import dead from './dead.png';
import firebase from "../../firebase";
import { createandshuffle, functionpicker } from './ocfunctions';


const Occult = () => {

    const [user, setuser] = useState("")

    //load user and manage auth
    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            setuser(user?.email ? user.email : "");
            console.log(user?.email);
        })
    }, [])

    const cards = {
        one: one,
        two: two,
        tree: tree,
        red: red,
        green: green,
        purple: purple,
        dead: dead
    }

    const ref = firebase.firestore().collection('ocgameobject').doc('gameobject');

    const [initialise, setinitialise] = useState(true);

    //the entire game
    const [gameobj, setgameobj] = useState({ loaded: false })

    const [player, setplayer] = useState('p');
    const [enemy, setenemy] = useState('');

    useEffect(() => {
        if (player != 'p') {
            if (player == 'p1') {
                setenemy('p2')
            }
            if (player == 'p2') {
                setenemy('p1')
            }
        }
    }, [player])

    //loading starting gameobject
    useEffect(() => {
        if (user == 'batoglevente@gmail.com') {
            if (initialise) {
                var deckandhand1 = createandshuffle();
                var deckandhand2 = createandshuffle();
                ref.set({
                    p1: {
                        decklist: deckandhand1[0],
                        hand: deckandhand1[1],
                        field: {
                            block1: {
                                active: true,
                                form: '',
                                runes: ['white', 'white', 'white']
                            },
                            block2: {
                                active: true,
                                form: '',
                                runes: ['white', 'white', 'white']
                            },
                            block3: {
                                active: true,
                                form: '',
                                runes: ['white', 'white', 'white']
                            },
                            block4: {
                                active: true,
                                form: '',
                                runes: ['white', 'white', 'white']
                            },
                            block5: {
                                active: true,
                                form: '',
                                runes: ['white', 'white', 'white']
                            }
                        }
                    },
                    p2: {
                        decklist: deckandhand2[0],
                        hand: deckandhand2[1],
                        field: {
                            block1: {
                                active: true,
                                form: '',
                                runes: ['white', 'white', 'white']
                            },
                            block2: {
                                active: true,
                                form: '',
                                runes: ['white', 'white', 'white']
                            },
                            block3: {
                                active: true,
                                form: '',
                                runes: ['white', 'white', 'white']
                            },
                            block4: {
                                active: true,
                                form: '',
                                runes: ['white', 'white', 'white']
                            },
                            block5: {
                                active: true,
                                form: '',
                                runes: ['white', 'white', 'white']
                            }
                        }
                    }
                })
            }
            setinitialise(false);
        }
        if (user == 's1m0nf3l1x@gmail.com') {
            setinitialise(false);
        }
    }, [initialise, user])

    //sinchronize changes to gameobject
    useEffect(() => {
        const snapshot = ref.onSnapshot(docSnapshot => {
            if (!initialise) {
                console.log(docSnapshot.data());
                setgameobj({ ...docSnapshot.data() });
            }
        })
        return () => { snapshot(); }
    }, [initialise])

    //select player
    useEffect(() => {
        if (user == 'batoglevente@gmail.com') {
            setplayer('p1')
        } else {
            setplayer('p2')
        }
    }, [player])

    useEffect(() => {
        console.log(player)
    }, [player])

    useEffect(() => {
        console.log(gameobj)
    }, [gameobj])

    const [source, setsource] = useState("");

    function selectingfromhand(clicked) {
        setsource(clicked)
    }

    function selectedbatlefield() {
        if (source != "" && (source == 'one' || source == 'two' || source == 'tree')) {
            var curentboard = { ...gameobj };
            console.log(source)
            console.log(curentboard[player].hand.indexOf(source))
            if (curentboard[player].hand.indexOf(source) == 0) {
                curentboard[player].hand.shift();
            } else {
                curentboard[player].hand.splice(curentboard[player].hand.findIndex(x => x == source), 1);
            }
            console.log(curentboard)
            if (curentboard[player].field.block1.form == "" || curentboard[player].field.block1.form == "dead") {
                curentboard[player].field.block1.form = source;
            } else if (curentboard[player].field.block2.form == "" || curentboard[player].field.block2.form == "dead") {
                curentboard[player].field.block2.form = source;
            } else if (curentboard[player].field.block3.form == "" || curentboard[player].field.block3.form == "dead") {
                curentboard[player].field.block3.form = source;
            } else if (curentboard[player].field.block4.form == "" || curentboard[player].field.block4.form == "dead") {
                curentboard[player].field.block4.form = source;
            } else if (curentboard[player].field.block5.form == "" || curentboard[player].field.block5.form == "dead") {
                curentboard[player].field.block5.form = source;
            } else {
                curentboard[player].hand.push(source);
            }
            ref.set({ ...curentboard })
        }
        setsource("");
    }

    function selectedform(block) {
        if (source != "" && (source != 'one' || source != 'two' || source != 'tree')) {
            var curentboard = { ...gameobj };
            console.log(source)
            console.log(curentboard[player].field[block].runes.indexOf(source))
            if (curentboard[player].hand.indexOf(source) == 0) {
                curentboard[player].hand.shift();
            } else {
                curentboard[player].hand.splice(curentboard[player].hand.findIndex(x => x == source), 1);
            }
            console.log(curentboard)
            if (curentboard[player].field[block].runes[0] == "white") {
                curentboard[player].field[block].runes[0] = source;
            } else if (curentboard[player].field[block].runes[1] == "white") {
                curentboard[player].field[block].runes[1] = source;
            } else if (curentboard[player].field[block].runes[2] == "white") {
                curentboard[player].field[block].runes[2] = source;
            } else {
                curentboard[player].hand.push(source);
            }
            ref.set({ ...curentboard })
        }
        //utólag került be lehet rossz
        settmagnify(block);
        setsource("");
    }

    function tapping(block) {
        if (source == "") {
            var curentboard = { ...gameobj };
            curentboard[player].field[block].active = !curentboard[player].field[block].active
            ref.set({ ...curentboard })
        }
    }

    function todraw() {
        var curentboard = { ...gameobj };
        curentboard[player].hand.push(curentboard[player].decklist.shift());
        ref.set({ ...curentboard })
    }

    function todmg() {
        var curentboard = { ...gameobj };
        curentboard[player].decklist.shift();
        ref.set({ ...curentboard })
    }

    function todiscard() {
        var curentboard = { ...gameobj };
        curentboard[player].decklist.push(source);
        curentboard[player].hand.splice(curentboard[player].hand.findIndex(x => x == source), 1);
        ref.set({ ...curentboard })
        setsource("");
    }

    // ezt lehet jobban is és amúgy itt tartok 
    const [popup, setpopup] = useState(false)
    const [tmagnify, settmagnify] = useState("")
    function topopup() {
        if(tmagnify!=""){
            setpopup(!popup);
        }
    }

    function tograve() {
        if(tmagnify!=""){
            var curentboard = { ...gameobj };
            curentboard[player].field[tmagnify].form="dead"
            ref.set({ ...curentboard })
        }
    }

    function runedestroy(x){
        if(tmagnify!=""){
            var curentboard = { ...gameobj };
            curentboard[player].field[tmagnify].runes[x]="white"
            ref.set({ ...curentboard })
        }
    }

    return (
        (player != 'p' && user != '' && (Object.keys(gameobj).length >= 2) && !initialise && enemy != '') ?
            <div className='oc-batlefields'>
                <div className='oc-popup' style={{ display: popup ? 'unset' : 'none' }}>
                    {
                        tmagnify != "" ?
                            <>
                                <div className='oc-card-popup'><img src={cards[gameobj[player].field[tmagnify].form] || ''} className='oc-cardfill' ></img></div>
                                <div onClick={()=>runedestroy(0)} className='oc-card-popup'><img src={cards[gameobj[player].field[tmagnify].runes[0]] || ''} className='oc-cardfill' ></img></div>
                                <div onClick={()=>runedestroy(1)} className='oc-card-popup'><img src={cards[gameobj[player].field[tmagnify].runes[1]] || ''} className='oc-cardfill' ></img></div>
                                <div onClick={()=>runedestroy(2)} className='oc-card-popup'><img src={cards[gameobj[player].field[tmagnify].runes[2]] || ''} className='oc-cardfill' ></img></div>
                            </>
                            :
                            <></>
                    }
                </div>
                <div className='oc-controller'>
                    <div className='oc-todraw'><p onClick={() => todraw()} className='oc-controller-font'>Draw</p></div>
                    <div className='oc-todeck'><p onClick={() => topopup()} className='oc-controller-font'>+</p></div>
                    <div className='oc-tograve'><p onClick={() => tograve()} className='oc-controller-font'>grave</p></div>
                    <div className='oc-tohand'><p onClick={() => todiscard()} className='oc-controller-font'>discard</p></div>
                    <div className='oc-todiscard'><p onClick={() => todmg()} className='oc-controller-font'>dmg</p></div>
                </div>
                <div className='oc-gui'>
                    <div className='oc-nmi-gui'>
                        <p className='oc-gui-font'>Name: {enemy}</p>
                        <p className='oc-gui-font'>Deck: {gameobj[enemy].decklist.length}/60</p>
                        <p className='oc-gui-font'>hand: {gameobj[enemy].hand.length}</p>
                    </div>
                    <div className='oc-player-gui'>
                        <p className='oc-gui-font'>Name: {player}</p>
                        <p className='oc-gui-font'>Deck: {gameobj[player].decklist.length}/60</p>
                        <p className='oc-gui-font'>hand: {gameobj[player].hand.length}</p>
                    </div>
                </div>
                <div className='oc-hand'>
                    {
                        gameobj[player].hand.map((card, index) => {
                            return <div key={index} className='oc-card-hand'><img onClick={() => selectingfromhand(card)} src={cards[card]} className='oc-cardfill' ></img></div>
                        })
                    }
                </div>
                <div className='oc-p1-battlefield' onClick={() => selectedbatlefield()}>
                    {
                        (gameobj[player].field.block1.form != "")
                            ?
                            <div key={101} onClick={() => selectedform('block1')} className='oc-card-battlefield'>
                                <div className={gameobj[player].field.block1.active ? 'oc-vessel-battlefield-active' : 'oc-vessel-battlefield-passive'}><img onClick={() => tapping('block1')} src={cards[gameobj[player].field.block1.form]} className='oc-cardfill' ></img></div>
                                <div className='oc-souls-battlefield'>
                                    <div style={{ backgroundColor: (gameobj[player].field.block1.runes[0]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[player].field.block1.runes[1]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[player].field.block1.runes[2]) }} className='oc-soul-battlefield'></div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        (gameobj[player].field.block2.form != "")
                            ?
                            <div key={102} onClick={() => selectedform('block2')} className='oc-card-battlefield'>
                                <div className={gameobj[player].field.block2.active ? 'oc-vessel-battlefield-active' : 'oc-vessel-battlefield-passive'}><img onClick={() => tapping('block2')} src={cards[gameobj[player].field.block2.form]} className='oc-cardfill' ></img></div>
                                <div className='oc-souls-battlefield'>
                                    <div  style={{ backgroundColor: (gameobj[player].field.block2.runes[0]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[player].field.block2.runes[1]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[player].field.block2.runes[2]) }} className='oc-soul-battlefield'></div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        (gameobj[player].field.block3.form != "")
                            ?
                            <div key={103} onClick={() => selectedform('block3')} className='oc-card-battlefield'>
                                <div className={gameobj[player].field.block3.active ? 'oc-vessel-battlefield-active' : 'oc-vessel-battlefield-passive'}><img onClick={() => tapping('block3')} src={cards[gameobj[player].field.block3.form]} className='oc-cardfill' ></img></div>
                                <div className='oc-souls-battlefield'>
                                    <div style={{ backgroundColor: (gameobj[player].field.block3.runes[0]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[player].field.block3.runes[1]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[player].field.block3.runes[2]) }} className='oc-soul-battlefield'></div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        (gameobj[player].field.block4.form != "")
                            ?
                            <div key={104} onClick={() => selectedform('block4')} className='oc-card-battlefield'>
                                <div className={gameobj[player].field.block4.active ? 'oc-vessel-battlefield-active' : 'oc-vessel-battlefield-passive'}><img onClick={() => tapping('block4')} src={cards[gameobj[player].field.block4.form]} className='oc-cardfill' ></img></div>
                                <div className='oc-souls-battlefield'>
                                    <div style={{ backgroundColor: (gameobj[player].field.block4.runes[0]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[player].field.block4.runes[1]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[player].field.block4.runes[2]) }} className='oc-soul-battlefield'></div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        (gameobj[player].field.block5.form != "")
                            ?
                            <div key={105} onClick={() => selectedform('block5')} className='oc-card-battlefield'>
                                <div className={gameobj[player].field.block5.active ? 'oc-vessel-battlefield-active' : 'oc-vessel-battlefield-passive'}><img onClick={() => tapping('block5')} src={cards[gameobj[player].field.block5.form]} className='oc-cardfill' ></img></div>
                                <div className='oc-souls-battlefield'>
                                    <div style={{ backgroundColor: (gameobj[player].field.block5.runes[0]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[player].field.block5.runes[1]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[player].field.block5.runes[2]) }} className='oc-soul-battlefield'></div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                </div>
                <div className='oc-p2-battlefield'>
                    {
                        (gameobj[enemy].field.block1.form != "")
                            ?
                            <div key={106} className='oc-card-battlefield'>
                                <div className={gameobj[enemy].field.block1.active ? 'oc-vessel-battlefield-active' : 'oc-vessel-battlefield-passive'}><img src={cards[gameobj[enemy].field.block1.form]} className='oc-cardfill' ></img></div>
                                <div className='oc-souls-battlefield'>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block1.runes[0]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block1.runes[1]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block1.runes[2]) }} className='oc-soul-battlefield'></div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        (gameobj[enemy].field.block2.form != "")
                            ?
                            <div key={107} className='oc-card-battlefield'>
                                <div className={gameobj[enemy].field.block2.active ? 'oc-vessel-battlefield-active' : 'oc-vessel-battlefield-passive'}><img src={cards[gameobj[enemy].field.block2.form]} className='oc-cardfill' ></img></div>
                                <div className='oc-souls-battlefield'>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block2.runes[0]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block2.runes[1]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block2.runes[2]) }} className='oc-soul-battlefield'></div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        (gameobj[enemy].field.block3.form != "")
                            ?
                            <div key={108} className='oc-card-battlefield'>
                                <div className={gameobj[enemy].field.block3.active ? 'oc-vessel-battlefield-active' : 'oc-vessel-battlefield-passive'}><img src={cards[gameobj[enemy].field.block3.form]} className='oc-cardfill' ></img></div>
                                <div className='oc-souls-battlefield'>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block3.runes[0]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block3.runes[1]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block3.runes[2]) }} className='oc-soul-battlefield'></div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        (gameobj[enemy].field.block4.form != "")
                            ?
                            <div key={109} className='oc-card-battlefield'>
                                <div className={gameobj[enemy].field.block4.active ? 'oc-vessel-battlefield-active' : 'oc-vessel-battlefield-passive'}><img src={cards[gameobj[enemy].field.block4.form]} className='oc-cardfill' ></img></div>
                                <div className='oc-souls-battlefield'>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block4.runes[0]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block4.runes[1]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block4.runes[2]) }} className='oc-soul-battlefield'></div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        (gameobj[enemy].field.block5.form != "")
                            ?
                            <div key={110} className='oc-card-battlefield'>
                                <div className={gameobj[enemy].field.block5.active ? 'oc-vessel-battlefield-active' : 'oc-vessel-battlefield-passive'}><img src={cards[gameobj[enemy].field.block5.form]} className='oc-cardfill' ></img></div>
                                <div className='oc-souls-battlefield'>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block5.runes[0]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block5.runes[1]) }} className='oc-soul-battlefield'></div>
                                    <div style={{ backgroundColor: (gameobj[enemy].field.block5.runes[2]) }} className='oc-soul-battlefield'></div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                </div>
            </div>
            :
            <></>
    )
}

export default Occult