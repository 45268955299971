import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';

interface bullet {
    type: string;
    alive: Boolean;
    positionx: number;
    positiony: number;
    frame: number;
}

interface fakit{
    key:string;
    positionx:number;
    positiony:number;
    alive:Boolean
}


const Bullet = (prop:fakit) => {
    function tobe(ornot:Boolean){
        return ornot ? "inherit" : "none";
    }

    return (
        <Icon className="icon bullet" icon='ant-design:fire-outlined' rotate="180deg" style={{top:`${prop.positiony}%`,left:`${prop.positionx}%`,display:tobe(prop.alive)}}></Icon>
    )
}

export default Bullet