import { fail } from "assert";
import { count } from "console";
import { read } from "fs";
import { useCallback, useEffect, useId, useState } from "react";


export function useOrientation() {

    const [orientation, setOrientation] = useState(false);

    useEffect(() => {
        setOrientation(window.screen.orientation.type == "landscape-primary" || window.screen.orientation.type == "landscape-secondary");
    }, [])

    window.screen.orientation.onchange = function (e) { if (window.screen.orientation.type == "landscape-primary" || window.screen.orientation.type == "landscape-secondary") { setOrientation(true) } else { setOrientation(false) }; }

    return orientation;
}

export function useInputmanager(key: string) {

    const [keypressed, setkeypressed] = useState(false)

    const keydownFunction = useCallback((event: KeyboardEvent) => {
        if (event.code == key) {
            setkeypressed(true);
        }
    }, []);

    const keyUpFunction = useCallback((event: KeyboardEvent) => {
        if (event.code == key) {
            setkeypressed(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", keydownFunction);
        return () => {
            document.removeEventListener("keydown", keydownFunction);
        };
    }, []);
    useEffect(() => {
        document.addEventListener("keyup", keyUpFunction);
        return () => {
            document.removeEventListener("keyup", keyUpFunction);
        };
    }, []);

    return keypressed
}

export function useTouchmanager() {

    const [touched, setTouched] = useState(false);
    const [touchposition, setTouchposition] = useState<number>(0);

    const touchStartFunction = useCallback((event: TouchEvent) => {
        setTouched(true);
        setTouchposition(Math.floor((event.touches[0].pageX / window.screen.availWidth) * 100));
    }, []);

    const TouchMoveFunction = useCallback((event: TouchEvent) => {
        setTouchposition(Math.floor((event.touches[0].pageX / window.screen.availWidth) * 100));
    }, []);

    const TouchEndFunction = useCallback((event: TouchEvent) => {
        setTouched(false)
    }, []);

    useEffect(() => {
        document.addEventListener("touchstart", touchStartFunction);
        return () => {
            document.removeEventListener("touchstart", touchStartFunction);
        };
    }, []);
    useEffect(() => {
        document.addEventListener("touchmove", TouchMoveFunction);
        return () => {
            document.removeEventListener("touchmove", TouchMoveFunction);
        };

    }, []);

    useEffect(() => {
        document.addEventListener("touchend", TouchEndFunction);
        return () => {
            document.removeEventListener("touchend", TouchEndFunction);
        };
    }, []);

    return { touched, touchposition }
}

export function usePlayermanager() {
    const Leftkeypressed = useInputmanager('ArrowLeft');
    const Rightkeypressed = useInputmanager('ArrowRight');
    const { touched, touchposition } = useTouchmanager();
    const [playerposition, setPlayerposition] = useState<number>(50);
    const [tick, settick] = useState(0)

    useEffect(() => {
        const playerloop = setInterval(() => {
            settick(tick => tick + 1);
        }, 60);
        return () => clearInterval(playerloop);
    }, [])

    useEffect(() => {
        if (tick >= 1) {
            settick(0);
            // console.clear();
        }
        // console.log(tick);
    }, [tick])

    useEffect(() => {
        if (tick == 1) {
            var playpos = playerposition;
            if (Leftkeypressed || (touched && (playerposition - touchposition > 0))) {
                playpos = playpos - 1;
            }
            if (Rightkeypressed || (touched && (playerposition - touchposition < 0))) {
                playpos = playpos + 1;
            }
            if (playpos < 10) {
                playpos = 10;
            }
            if (playpos > 90) {
                playpos = 90;
            }
            setPlayerposition(Math.floor(playpos));
        }
    }, [playerposition, Leftkeypressed, Rightkeypressed, touched, touchposition, tick]);

    return playerposition;
}

interface enemi {
    key: string;
    alive: Boolean;
    positionx: number;
    positiony: number;
    frame: number;
    immune: boolean;
}

export function useEnemiesmanager(num: number) {
    const [enemies, setenemies] = useState<enemi[]>([{ alive: true, key: generaterandomkey(), positionx: 20, positiony: 20, frame: 0, immune: false }]);
    const [counter, setcounter] = useState(0)
    const [difi, setdifi] = useState(0);

    useEffect(() => {
        var enemiesgrid = enemies;
        for (let i = 0; i < num; i++) {
            enemiesgrid[i] = { alive: true, key: generaterandomkey(), positionx: (5 + (((i % 7) + 1) * 10)), positiony: (2 + ((Math.floor(i / 7) + 1) * 10)), frame: 0, immune: ((Math.random()*100)>60) };
        }
        setenemies([...enemiesgrid]);
    }, [])

    useEffect(() => {
        var enemiarray = enemies;
        if (counter == 10) {
            for (let i = 0; i < enemiarray.length; i++) {
                enemiarray[i].positiony = enemiarray[i].positiony + 10;
            }
            setcounter(0);
            setenemies([
                { alive: true, key: generaterandomkey(), positionx: (5 + (((0 % 7) + 1) * 10)), positiony: 12, frame: 0, immune: ((Math.random()*100)>60) },
                { alive: true, key: generaterandomkey(), positionx: (5 + (((1 % 7) + 1) * 10)), positiony: 12, frame: 0, immune: ((Math.random()*100)>60) },
                { alive: true, key: generaterandomkey(), positionx: (5 + (((2 % 7) + 1) * 10)), positiony: 12, frame: 0, immune: ((Math.random()*100)>60) },
                { alive: true, key: generaterandomkey(), positionx: (5 + (((3 % 7) + 1) * 10)), positiony: 12, frame: 0, immune: ((Math.random()*100)>60) },
                { alive: true, key: generaterandomkey(), positionx: (5 + (((4 % 7) + 1) * 10)), positiony: 12, frame: 0, immune: ((Math.random()*100)>60) },
                { alive: true, key: generaterandomkey(), positionx: (5 + (((5 % 7) + 1) * 10)), positiony: 12, frame: 0, immune: ((Math.random()*100)>60) },
                { alive: true, key: generaterandomkey(), positionx: (5 + (((6 % 7) + 1) * 10)), positiony: 12, frame: 0, immune: ((Math.random()*100)>60) },
                ...enemiarray.filter(nmi => nmi.alive)]);
        }
    }, [counter, enemies])

    useEffect(() => {
        var enemiarray = enemies;
        for (let i = 0; i < enemiarray.length; i++) {
            enemiarray[i].frame = enemiarray[i].frame == 0 ? 1 : 0;
            if (enemiarray[i].immune && ((Math.random() * 100) > ((100-(enemiarray[i].positiony-60))+( difi >= 15 ? 15 : difi)))) {
                enemiarray[i].immune = false;
            }
            if(enemiarray[i].positiony>62){
                enemiarray[i].immune=false;
            }
        }
    }, [counter, enemies])

    const setNmi = (bul: enemi[]) => { setenemies(bul) };
    const setdif = (dif: number) => { setdifi(dif) };

    useEffect(() => {
        const enemiloop = setInterval(() => {
            setcounter(counter => counter + 1);
        }, 1200);
        return () => clearInterval(enemiloop);
    }, []);

    return { enemies, setenemies, setdif };
}

interface blazingbullet {
    key: string,
    alive: Boolean,
    positionx: number,
    positiony: number
}

function generaterandomkey() {
    var randomkey = Math.random().toString().split('.')[1].slice(0, 6);
    return randomkey;
}

export function useBulletmanager() {

    const [bullets, setBullet] = useState<blazingbullet[]>([]);
    const [position, setPosition] = useState<number>(50);
    const [tick, settick] = useState(0)

    useEffect(() => {
        const bulletrloop = setInterval(() => {
            settick(tick => tick + 1);
        }, 600);
        return () => clearInterval(bulletrloop);
    }, [])

    useEffect(() => {
        if (tick >= 1) {
            settick(0);
            // console.clear();
        }
        // console.log(tick);
    }, [tick])


    const setPos = (pos: number) => { setPosition(pos) };
    const setBull = (bul: blazingbullet[]) => { setBullet(bul) };

    useEffect(() => {
        if (tick == 1) {
            var poscorrected = position - 1.5// + (left ? -7 : 0) + (right ? 7 : 0)
            var bulletarray = bullets;
            for (let i = 0; i < bulletarray.length; i++) {
                var pos = bulletarray[i].positiony;
                pos -= 10;
                bulletarray[i].positiony = pos;
            }
            setBullet([...bulletarray.filter(curbullet => curbullet.positiony > 0), { key: generaterandomkey(), alive: true, positionx: poscorrected, positiony: 90 }]);
        }
    }, [bullets, tick, position]);

    return { bullets, setBull, setPos };
}

export function useCounter() {

    const [count, setcount] = useState(0);
    const [ready, setReady] = useState(false);

    const setcounter = () => {
        if (count == 4) {
            setReady(true);
            setcount(0);
        } else {
            setcount(count + 1)
        }
    };



    return { count, ready, setcounter }
}
