import './App.css';
import Navbar from './components/navbar/Navbar';
import Mainpage from './components/mainpage/Mainpage';


function App() {

  return (
    <div className="App">
      <Navbar />
      <Mainpage />
    </div>
  );
}

export default App;
