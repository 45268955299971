import Navbar from "../../components/navbar/Navbar";
import './projects.css';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react'

export default function Projects() {
    return (
        <div className="App">
            <Navbar />
            <h1>Projects</h1>
            <main className="projectlist">
                <a href="https://github.com/BatLev/"><Icon className="icon" icon="akar-icons:github-outline-fill"></Icon></a>
                <Link className='nav-link' to="/toDo"><Icon className="icon" icon="bx:list-check"></Icon></Link>
                <Link className='nav-link' to="/invaders"><Icon className="icon" icon="mdi:space-invaders"></Icon></Link>
                <Link className='nav-link' to="/cardmaker"><Icon className="icon" icon="akar-icons:credit-card"></Icon></Link>
                <Link className='nav-link' to="/occult"><Icon className="icon" icon="mdi:cards-outline"></Icon></Link>
            </main>
        </div>
    );
}