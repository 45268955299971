import Navbar from "../../components/navbar/Navbar";
import '../../App.css';
import './invaders.css';
import { Icon } from '@iconify/react'
import { FC, useCallback, useEffect, useState } from "react";
import './functions';
import { useNavigate } from "react-router-dom";
import firebase from "../../firebase";



const Gameover: FC = () => {

  const navigate = useNavigate();

  const [scores, setscores] = useState([{name:'',score:'0'}]);

  const ref = firebase.firestore().collection("invaders_hightscore");

  useEffect(() => {
    console.log(scores);
  }, [scores])
  

  useEffect(() => {
    ref.onSnapshot((querySnapshot) => {
      const items:any = [];
      querySnapshot.forEach((doc)=>{
        items.push({name:doc.id.split('@')[0],score:doc.data().score})
      })
      console.log('I read one times')
      setscores(items)
    })
  }, [])
  


  return (
    <div className="App">
      <Navbar />
      <main className='content'>
        <div className="space" style={{display:'flex',flexDirection:'column',justifyContent:"start",alignItems:'center'}}>
          <h1 style={{display:'flex',fontSize:'0.7rem'}} >Game over!</h1>
          <h1 style={{display:'flex',fontSize:'0.7rem'}} >Try again?</h1>
          <Icon icon="mdi:space-invaders" style={{display:'flex',fontSize:'2rem'}} onClick={()=>navigate('/invaders')}></Icon>
          <div className="highscore" style={{fontSize:'0.75rem'}}>
            <h1>HighScores:</h1>
              {scores.sort((a, b) => (a.score < b.score) ? 1 : -1).map((score,index)=>{
                return <p key={index}>{index+1}:{score.name}:{score.score}P</p>
              })}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Gameover;