import React, { FC, useState, useEffect } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Cardv2 from '../../components/card/Cardv2';
import firebase from '../../firebase';
import './cardmaker.css';

//a végére legyenmár megint typescript
const Cardmaker = () => {

    const ref = firebase.firestore().collection("cardmakercards");

    function cardupload(card){
            const cardRef = ref.doc(card.name);
            const res = cardRef.set(card).then(() => window.location.reload());
    }

    function rulesupload(text){
        const cardRef = ref.doc('rules');
        const res = cardRef.set({rules:text}).then(() => window.location.reload());
}

    const [rulesopen, setrulesopen] = useState(false);
    const [cardmakeropen, setcardmakeropen] = useState(true);
    const [galeryopen, setgaleryopen] = useState(true);
    const [createdcard, setcreatedcard] = useState({
        base: 0,
        rune1: 0,
        rune2: 0,
        rune3: 0,
        icon1: 0,
        icon2: 0,
        bpmodifier: 0,
        bpmod: 0,
        name: '',
        skill1: '',
        skill2: ''
    })
    const [galerrycontent, setgalerrycontent] = useState([{
            base: 0,
            rune1: 0,
            rune2: 0,
            rune3: 0,
            icon1: 0,
            icon2: 0,
            bpmodifier: 0,
            bpmod: 0,
            name: 'loading...',
            skill1: 'loading...',
            skill2: 'loading...'
    }])

    const [rules, setrules] = useState("Rules:");

    useEffect(() => {
        const snapshot = ref.doc('rules');
        snapshot.get().then(data => data.data()).then(rules => setrules(rules.rules))
    }, [])
    
    useEffect(() => {
    var galery=[{}];
    const snapshot = ref.get().then(ss=>ss.forEach(card => galery.push(card.data()))).then(s => {console.log(galery);setgalerrycontent([...galery])});
}, [])
    
    //adatbáziskapcsolat
    //bundle size csökkentés

    return (
        <div className="App">
            <Navbar />
            <main className='cardmakercontent'>
                {rulesopen ?
                    <div className='cardmakerrules'>
                        <p className='cardmakertitle' onClick={() => setrulesopen(!rulesopen)}>Rules</p>
                        <textarea value={rules} rows={2+rules.length/17} className='cardrules' onChange={(e)=>setrules(e.target.value)}/>
                        <p className='cardmakersubmit' onClick={() => {rulesupload(rules)}}>Submit</p>
                    </div>
                    :
                    <div className='cardmakerrules'>
                        <p className='cardmakertitle' onClick={() => setrulesopen(!rulesopen)}>Rules</p>
                    </div>
                }
                {cardmakeropen ?
                    <div className='cardmaker'>
                        <p className='cardmakertitle' onClick={() => setcardmakeropen(!cardmakeropen)}>Cardmaker</p>
                        <Cardv2 readonly={false} card={null} funk={createdcard => setcreatedcard(createdcard)}/>
                        <p className='cardmakersubmit' onClick={() => cardupload(createdcard)}>Submit</p>
                    </div>
                    :
                    <div className='cardmaker'>
                        <p className='cardmakertitle' onClick={() => setcardmakeropen(!cardmakeropen)}>Cardmaker</p>
                    </div>
                }
                {
                    galeryopen ?
                        <div className='cardmakergalery'>
                            <p className='cardmakertitle' onClick={() => setgaleryopen(!galeryopen)}>Gallery</p>
                            {galerrycontent.map((card,index)=>{
                                if(card.name){
                                    return <Cardv2 key={index} readonly={true} card={card} funk={null}/>
                                }else{
                                    
                                }

                            })}
                        </div>
                        :
                        <div className='cardmakergalery'>
                            <p className='cardmakertitle' onClick={() => setgaleryopen(!galeryopen)}>Gallery</p>
                        </div>
                }
            </main >
        </div >
    )
}

export default Cardmaker

{/* <game>>

hold(víz,holtak)/nap(tűz,ember)/csillagok(eldrich,void,strange,travelling,)?,föld?,

mi a flow felbontásának célja?semmi.helyette 1/2/3 támadós lapok és az aktuálisan aktív cumók felbontása

                        <Cardv2 readonly={true} card={{
                            base: 2,
                            rune1: 0,
                            rune2: 1,
                            rune3: 2,
                            icon1: 0,
                            icon2: 0,
                            bpmodifier: 0,
                            bpmod: 0,
                            name: 'teszt',
                            skill1: 'teszt',
                            skill2: 'teszt'
                        }} />

<game/> */}