import React, { FC } from 'react'
import { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react'
import './navbar.css';
import firebase from '../../firebase';

const Navbar: FC = () => {

    // const [navbar, setnavbar] = useState(false)
    const [loggedin, setloggedin] = useState(false)

    const SignIn = ()=>{
        firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
        console.log("logged in");
    };
    const Signout = ()=>{
        firebase.auth().signOut();
        console.log("logged out");
    };

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user)=>{
            if(user){
                setloggedin(true);
            }else{
                setloggedin(false);
            }
        })
    }, [])
    

    return (
        <nav className='navbar'>
            <div className='navbar-base'>
                <div className='navbar-links'>
                    <Link className='nav-link' to="/"><Icon className="icon" icon="codicon:home"></Icon></Link>
                    <Link className='nav-link' to="/"><div className='title'>Main</div></Link>
                </div>
                <div className='navbar-links'>
                    <Link className='nav-link' to="/aboutMe"><Icon className="icon" icon="ph:linkedin-logo"></Icon></Link>
                    <Link className='nav-link' to="/aboutMe"><div className='title'>About me</div></Link>

                </div>
                <div className='navbar-links' >
                    <Link className='nav-link' to='/projects'><Icon className="icon" icon="akar-icons:github-outline-fill"></Icon></Link>
                    <Link className='nav-link' to='/projects'><div className='title'>Projects</div></Link>
                </div>
                <div className='navbar-links' onClick={() =>{(loggedin) ? Signout() : SignIn()}}>
                    <Link className='nav-link' to='#'><Icon className="icon" icon="carbon:user-profile"></Icon></Link>
                    <Link className='nav-link' to='#'><div className='title'>{loggedin ? 'Logout' : 'Login'}</div></Link>
                </div>
            </div>
        </nav>
    )
}

export default Navbar