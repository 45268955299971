import { useState, useEffect } from 'react'
import './cardv2.css';
// import { Icon } from '@iconify/react';
import form from './form.png';
import redbase from './redcore.png';
import greenbase from './greencore.png';
import lilacbase from './lilaccore.png';
import redrune from './redrune.png';
import greenrune from './greenrune.png';
import lilacrune from './lilacrune.png';
import baserune from './baserune.png';
import attack from './battered-axe.png';
import defense from './police-badge.png';
// import draw from './card-draw.png';
import drop from './card-drop.png';
// import droprandom from './card-pickup.png';
import summon from './flexible-star.png';
// import place from './save-arrow.png';
import passive from './infinity.png';
// import noshield from './shield-disabled.png';
import death from './surprised-skull.png';
// import attacksword from './sword-brandish.png';
import cardburn from './card-burn.png';
import zero from './0.png';
import one from './1.png';
import two from './2.png';
import three from './3.png';
import four from './4.png';
import five from './5.png';
import recycle from './recycle.png';
import plusone from './plusone.png';
import plustwo from './plustwo.png';
import pluszero from './pluszero.png';
import minusone from './minusone.png';
import minustwo from './minustwo.png';


const Cardv2 = (prop: {
  readonly: boolean,
  funk: Function,
  card: {
    base: number,
    rune1: number,
    rune2: number,
    rune3: number,
    icon1: number,
    icon2: number,
    bpmodifier: number,
    bpmod: number,
    name: string,
    skill1: string,
    skill2: string
  }
}) => {

  const [cardsize, setcardsize] = useState(0)
  useEffect(() => {
    setcardsize(((document?.getElementById('card')?.clientWidth || 160) / 8))
    console.log('resized');
  },[])


  useEffect(() => {
    if (prop.card != null) {
      setbase(bases[prop.card.base])
      setrune1(runes[prop.card.rune1])
      setrune2(runes[prop.card.rune2])
      setrune3(runes[prop.card.rune3])
      seticon1(icons[prop.card.icon1])
      seticon2(icons[prop.card.icon2])
      setbpmodifier(numbers[prop.card.bpmodifier])
      setbpmod(modnumbers[prop.card.bpmod])
      setname(prop.card.name)
      setskill1(prop.card.skill1)
      setskill2(prop.card.skill2)
    }
  }, [])


  const [name, setname] = useState('name');
  const [skill1, setskill1] = useState('skill1');
  const [skill2, setskill2] = useState('skill2')

  const [base, setbase] = useState<string>(form)
  const bases = [form, redbase, greenbase, lilacbase];
  function baseroller(value: string) {
    if (bases.findIndex(obj => obj == value) == bases.length - 1) {
      return bases[0];
    } else {
      return bases[bases.findIndex(obj => obj == value) + 1]
    }
  }

  const [rune1, setrune1] = useState<string>(baserune);
  const [rune2, setrune2] = useState<string>(baserune);
  const [rune3, setrune3] = useState<string>(baserune);
  const runes = [redrune, greenrune, lilacrune, baserune, ""];
  function runeroller(value: string) {
    if (runes.findIndex(obj => obj == value) == runes.length - 1) {
      return runes[0];
    } else {
      return runes[runes.findIndex(obj => obj == value) + 1]
    }
  }

  const [icon1, seticon1] = useState<string>(attack);
  const [icon2, seticon2] = useState<string>(drop);
  const icons = [attack, defense, drop, summon, passive, death, cardburn, recycle];
  function iconroller(value: string) {
    if (icons.findIndex(obj => obj == value) == icons.length - 1) {
      return icons[0];
    } else {
      return icons[icons.findIndex(obj => obj == value) + 1]
    }
  }

  const [bpmodifier, setbpmodifier] = useState(zero)
  const numbers = [zero, one, two, three, four, five];
  function numberroller(value: string) {
    if (numbers.findIndex(obj => obj == value) == numbers.length - 1) {
      return numbers[0];
    } else {
      return numbers[numbers.findIndex(obj => obj == value) + 1]
    }
  }

  const [bpmod, setbpmod] = useState(pluszero);
  const modnumbers = [pluszero, plusone, plustwo, minusone, minustwo,];
  function modnumberroller(value: string) {
    if (modnumbers.findIndex(obj => obj == value) == modnumbers.length - 1) {
      return modnumbers[0];
    } else {
      return modnumbers[modnumbers.findIndex(obj => obj == value) + 1]
    }
  }

  useEffect(() => {
    if (prop.funk) {
      prop.funk({
        base: bases.findIndex(b => b == base)
        , rune1: runes.findIndex(b => b == rune1)
        , rune2: runes.findIndex(b => b == rune2)
        , rune3: runes.findIndex(b => b == rune3)
        , icon1: icons.findIndex(b => b == icon1)
        , icon2: icons.findIndex(b => b == icon2)
        , bpmodifier: numbers.findIndex(b => b == bpmodifier)
        , bpmod: modnumbers.findIndex(b => b == bpmod)
        , name: name
        , skill1: skill1
        , skill2: skill2
      })
    }
  }, [base, rune1, rune2, rune3, icon1, icon2, bpmodifier, bpmod, name, skill1, skill2])

  return (
    <div className='cont'>
      <div className='card' id='card'>
        <input className='cardname' disabled={prop.readonly} value={name} onChange={(e) => { setname(e.target.value) }} style={{ fontSize: (cardsize + "px") }} ></input>
        <textarea className='skilltext1' disabled={prop.readonly} value={skill1} onChange={(e) => { setskill1(e.target.value) }} style={{ fontSize: ((cardsize / 3) + "px") }}></textarea>
        <textarea className='skilltext2' disabled={prop.readonly} value={skill2} onChange={(e) => { setskill2(e.target.value) }} style={{ fontSize: ((cardsize / 3) + "px") }}></textarea>
        <img className='baseplate' src={base} alt="back" onClick={() => !prop.readonly ? setbase(baseroller(base)) : {}} />
        {rune1 != "" ?
          <img className='rune1' src={rune1} alt="" onClick={() => !prop.readonly ? setrune1(runeroller(rune1)) : {}} />
          :
          <img className='rune1' src={runes[0]} alt="" style={{ opacity: 0.01 }} onClick={() => !prop.readonly ? setrune1(runeroller(rune1)) : {}} />
        }
        {rune2 != "" ?
          <img className='rune2' src={rune2} alt="" onClick={() => !prop.readonly ? setrune2(runeroller(rune2)) : {}} />
          :
          <img className='rune2' src={runes[0]} alt="" style={{ opacity: 0.01 }} onClick={() => !prop.readonly ? setrune2(runeroller(rune2)) : {}} />
        }
        {rune3 != "" ?
          <img className='rune3' src={rune3} alt="" onClick={() => !prop.readonly ? setrune3(runeroller(rune3)) : {}} />
          :
          <img className='rune3' src={runes[0]} alt="" style={{ opacity: 0.01 }} onClick={() => !prop.readonly ? setrune3(runeroller(rune3)) : {}} />
        }
        <img className='skill1' src={icon1} alt="back" onClick={() => !prop.readonly ? seticon1(iconroller(icon1)) : {}} />
        {base == bases[0] ?
          <img className='skill2' src={icon2} alt="back" onClick={() => !prop.readonly ? seticon2(iconroller(icon2)) : {}} />
          :
          <img className='bpmodifier' src={bpmod} alt="back" onClick={() => !prop.readonly ? setbpmod(modnumberroller(bpmod)) : {}} />
        }
        {base == bases[0] ?
          <img className='bp' src={bpmodifier} alt="back" onClick={() => !prop.readonly ? setbpmodifier(numberroller(bpmodifier)) : {}} />
          :
          <></>
        }
      </div>
    </div>
  )
}

export default Cardv2

{/* <game>

cost:
determinate what resources i need,there are optionals(blanks) 
for weapons and runes.the are cores witchs are need to be on 
the board.they dont go numb and have stats on them

type of cores:
moon  / blue
sun   / yellow
earth / green 

or

 simpli blue red green where red creates blue destroy and green preserve 

 core:
 -type
 -color
 -stats
 -batlecry and summon requirement(maybe even tribiute?)
 -discard abilitues

 form:
 -costs
 -stats(maybe with min and max)
 -batlecry and summon req +passives
 -discard abilities(maybe one form and multiple skill)
 
 differences:
 -cost
 -color
 -type

 solutions:
 -cost: just put it on the right as it was before
 -color: not a problem,, it will be background
 -type:
 {
   what are the types?
   -core
   -weapon
   -rune
   what are the differences:
   -core for summon
   -weapons gives attack enchatments
   -runes gives passives
   what is the solution:
   -icon for passives and attack enchantments
 }

  <game/> */}

{/*<gameplay>

váltott körös kártyajáték amiben a paklid a hp-d.
vannak hangsúlyos úgynevezett core lapok 
a harcrendszer lehetőleg pont alapú
a játékos kezében 5 lap van és ha egyik játékos sem tud cselekedni akkor húznak csak ujjat

...implementing... 

simán lehetnek pici erőforrás lények amik mellesleg core-ok 

esetleg legyen a támadás is egy akció lehetőség ? vagy legyen lap dobós effekt?
esetleg univerzális effekt lapdobáslrt és minden támadó után fizetsz?

use more universaL game mechanics.lehet elég a támadó érték ? ?? ?? ?? ?? ? és legyen több átok meg módosító?

ne feledjük a szupermenő pajzs mechanikákat.és minden szart jelöljünk ikonnal.de max 5-6 al

mi és mior kerül a temetőbe?
esetleg két oldalú kártyák legyenek azok amik az ellenfélhez kerületnek?
ahol lehet a keywordok legyenek univerzálisak

////////////////////////
        Tisztázat
////////////////////////
a játékot két játékos játsza akik 5-5 lapot húznak a paklijukbol és felváltva vagy lapot játszanak ki(pentagram ikon)
vagy pedig lapot dobnak(dobós ikon)a forma megidézéséhez szükséges annyi core amennyi rá van írva.ekkor kisülnek képességei.
a core ingyé van ha mást nem ír és szintén lehetnek kijátszáskori képességei.a core és a szörny is rendelkezik egy számmal 
amik összeadásából adódik a harci értéke.a lapokon szerepelhet támadás ikon.ha ilyen ikon jön létre akkor a kijátszó támad 
minimum egy lényével és az ellenfele kiválaszthat akárhány lényt akik védekezni fognak.a harci értékeik külömbségéből adódóan
az alacsonyabb pont birtokosa sebződi el a külömbségeta harc végére minden szörny elfárad és csak akkor aktivizálódnak mikor
uj nagy kör kezdődik.a sebzést a játékos a paklija tetejéről a temetőbe rakja.ha egy játékos képtelen 5 lapot húzni akkor
elvesztette a játékot. a dobott lapjaiból minden játékos a kör végén annyit keverhet vissza a paklijába (random szupermenő
jutalmazó mechanika helye)bármely szörny blokkolhatja a támadás egy nagyobb részét az integritása árán.ekkor dobj el
róla egy core-t.ha nincs több core egy formán akkor elpusztul.ekkor annyi damaget véd le amennyi a harci értéke.a 
játékot 30lapos paklival játszák.nincs rá semmi más megkötés.
(a dobós képességekkel kezdeni kell valamit)
(a három erőforrás identitásával is)
///////////////////////
(shield mechanikák arra az esetre ha rúnát dobnál // vagy lehet alapbol effekttelenül az asztalra kerül )
vagy a formák visszakeverednek, a core-ok kidobódnak , és csak néha meg esetleg van speciális effektjük


</gameplay>*/}