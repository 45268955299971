import React, { FC } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react'
import './mainpage.css';

const Mainpage: FC = () => {

    return (
        <div className='mainpage'>
            <h1>Wellcome!</h1>
            <p>I am Levente Batóg and this is my personal page. Currently there isn't much to see but feel free to look around.</p>
            <p>------------------------------------------------------------------------------------------</p>
            <p style={{ textTransform: 'uppercase' }}>I really appreciated the feedback from my beta testers on the space invader copy so i made a few update to make it more enjoyable.</p>
            <p> THX D & G </p>
            <p>------------------------------------------------------------------------------------------</p>
            {/* <iframe width="80%" height="60" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1135308790&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe> */}
        </div>
    )
}

export default Mainpage